export const residencies = [
  {
    id: 'domestic',
    name: 'Domestic',
    default: true,
  },
  {
    id: 'international',
    name: 'International',
    default: false,
  },
];

export const defaultResidency = () => {
  const result = residencies.filter((element) => element.default === true);
  if (!result.length) {
    return residencies[0].id;
  }
  return result[0].id;
};
