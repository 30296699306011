export default {
  studentType(state) {
    let residency = '';
    let level = '';

    switch (state.profile.residency) {
      case 'domestic':
        residency = 'domestic';
        break;
      case 'international':
        residency = 'international';
        break;
      default:
        residency = 'domestic';
        break;
    }

    switch (state.profile.qualification) {
      case 'undergrad':
        level = 'undergraduate';
        break;
      case 'postgrad':
        level = 'graduate';
        break;
      case 'research':
        level = 'research';
        break;
      default:
        level = 'undergraduate';
        break;
    }
    return {
      residency,
      level,
    };
  },
  getProfile(state) {
    return state.profile;
  },
  getFeatureFlags(state) {
    return state.featureFlags;
  },
};
